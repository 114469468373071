import styled from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import {
  DURATION,
  DURATION_500,
  fadeIn,
  HEIGHT_S,
  SHADOW,
  SHADOW_MODAL,
  SHADOW_MODAL_MOBILE,
  translateXIn,
  translateXOut,
  translateYIn,
  translateYOut,
  Z_INDEX
} from '../../../../styles';

interface IModalContent {
  size?:
    | 'l'
    | 'm'
    | 's'
    | 'xs'
    | 'xxs'
    | 'newxxs'
    | 'recording'
    | 'import-clients'
    | 'push'
    | 'time-table'
    | 'messenger-editor'
    | 'note-creator'
    | 'register-time-table'
    | 'category-creator'
    | 'confirm-delete'
    | 'telegram-bot'
    | 'new-sales'
    | 'profile-exit'
    | 'cash-register-icon';
}

export const ModalWrapper = styled.div<IModalContent>`
  bottom: auto;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'telegram-bot':
          return props.size ? 'center' : '';
        case 'profile-exit':
          return props.size ? 'center' : '';
        default:
          return 'flex-start';
      }
    } else {
      return 'flex-start';
    }
  }};
  outline: none;
  overflow: auto;
  justify-content: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'category-creator':
          return props.size ? 'center' : '';
        case 'note-creator':
          return props.size ? 'center' : '';
        case 'messenger-editor':
          return props.size ? 'center' : '';
        case 'telegram-bot':
          return props.size ? 'center' : '';
        case 'profile-exit':
          return props.size ? 'center' : '';
        default:
          return '';
      }
    } else {
      return '';
    }
  }};
  z-index: ${Z_INDEX};
  &::-webkit-scrollbar {
    display: none;
  }
  &.side {
    left: auto;
    height: 100%;
    margin-top: auto;
    bottom: 0;
    overflow: visible;
    width: fit-content;
    @media (max-width: 767px) {
      height: 100%;
    }
  }
  &.side-trasparent {
    left: auto;
    height: calc(100% - 144px);
    margin-top: auto;
    bottom: 0;
    overflow: visible;
    width: fit-content;
    @media (max-width: 767px) {
      height: 100%;
    }
  }
  &.side-overlay {
    height: 100%;
  }
  &.side-overlay-transparent {
    height: 100%;
  }
  &.create-lesson {
    .side-overlay-transparent > div {
      padding: 0;
    }
  }
  &.top {
    margin-top: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'category-creator':
            return props.size ? '24px' : '';
          case 'new-sales':
            return props.size ? '-16px' : '';
          default:
            return '0';
        }
      } else {
        return '0';
      }
    }};
  }
  &.open {
    display: flex;
    visibility: visible;
  }
  &.close {
    display: none;
    visibility: hidden;
  }
`;

export const ModalContainer = styled.div<IModalContent>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'note-creator':
          return props.size ? '1.5rem 0 0 0' : '';
        case 'messenger-editor':
          return props.size ? '1.5rem 0 0 0' : '';
        default:
          return '';
      }
    } else {
      return '';
    }
  }};
  box-sizing: border-box;
  border: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'telegram-bot':
          return props.size ? 'none' : 'none';
        case 'category-creator':
          return props.size ? 'none' : '';
        case 'note-creator':
          return props.size ? 'none' : '';
        case 'messenger-editor':
          return props.size ? 'none' : '';
        // case 'new-sales':
        //   return props.size ? 'none' : '';
        default:
          return '2.5rem solid transparent';
      }
    } else {
      return '2.5rem solid transparent';
    }
  }};
  margin: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'telegram-bot':
          return props.size ? 'none' : 'none';
        case 'category-creator':
          return props.size ? '24px' : '24px';
        case 'note-creator':
          return props.size ? 'none' : 'none';
        case 'messenger-editor':
          return props.size ? 'none' : 'none';
        default:
          return 'auto';
      }
    } else {
      return 'auto';
    }
  }};
  max-width: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'import-clients':
          return props.size ? '90vw' : '90vw';
        case 'recording':
          return props.size ? '90rem' : '90rem';
        case 'l':
          return props.size ? '65rem' : '47.5rem';
        case 'm':
          return props.size ? '47.5rem' : '47.5rem';
        case 'xs':
          return props.size ? '38.25rem' : '47.5rem';
        case 'newxxs':
          return props.size ? '512px' : '42.5rem';
        case 'confirm-delete':
          return props.size ? '480px' : '42.5rem';
        case 'time-table':
          return props.size ? '48.75rem' : '47.5rem';
        case 'note-creator':
          return props.size ? '460px' : '47.5rem';
        case 'messenger-editor':
          return props.size ? '448px' : '47.5rem';
        case 'register-time-table':
          return props.size ? '570px' : '47.5rem';
        case 'category-creator':
          return props.size ? '755px' : '47.5rem';
        case 'telegram-bot':
          return props.size ? '794px' : '47.5rem';
        case 'profile-exit':
          return props.size ? 'calc(423px + 5rem)' : '47.5rem';
        case 'new-sales':
          return props.size ? 'calc(389px + 5rem)' : '';
        case 'cash-register-icon':
          return props.size ? '71rem' : '47.5rem';
        default:
          return '42.5rem';
      }
    } else {
      return '42.5rem';
    }
  }};
  width: ${(props) => {
    // if (props.$width) {
    //   return props.$width;
    // }
    if (props.size !== undefined) {
      switch (props.size) {
        case 'category-creator':
          return props.size ? '755px' : '755px';
        case 'import-clients':
          return props.size ? 'fit-content' : 'fit-content';
        case 'telegram-bot':
          return props.size ? '794px' : '47.5rem';
        default:
          return '100%';
      }
    } else {
      return '100%';
    }
  }};
  height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'telegram-bot':
          return props.size ? '707px' : '707px';
        default:
          return '';
      }
    } else {
      return '0';
    }
  }};
  align-items: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'telegram-bot':
          return props.size ? 'center' : 'center';
        default:
          return '';
      }
    } else {
      return '0';
    }
  }};
  &.side {
    margin: 0;
    border: none;
    height: 100%;
  }
  &.side-transparent {
    margin: 0;
    border: none;
    height: 100%;
  }
  &.top {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    border: none;
    width: 100vw;
    overflow-x: hidden;
    bottom: 0;
    border-radius: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'recording':
            return props.size ? '0' : '0';
          case 'l':
            return props.size ? '0' : '0';
          case 'm':
            return props.size ? '0' : '0';
          case 'xs':
            return props.size ? '1rem' : '0';
          case 'xxs':
            return props.size ? '1rem' : '0';
          case 'push':
            return props.size ? '1rem 1rem 0 0' : '0';
          case 'time-table':
            return props.size ? '0' : '0';
          default:
            return '0';
        }
      } else {
        return '0';
      }
    }};
    margin: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'recording':
            return props.size ? 'unset' : 'unset';
          case 'category-creator':
            return props.size ? '24px' : '0';
          case 'l':
            return props.size ? 'unset' : 'unset';
          case 'm':
            return props.size ? 'unset' : 'unset';
          case 'xs':
            return props.size ? 'auto auto 1rem' : 'unset';
          case 'xxs':
            return props.size ? 'auto auto 1rem' : 'unset';
          case 'push':
            return props.size ? 'auto 0 0' : 'unset';
          default:
            return 'unset';
        }
      } else {
        return 'unset';
      }
    }};
    max-width: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'telegram-bot':
            return props.size ? '794px' : '47.5rem';
          case 'import-clients':
            return props.size ? '100vw !important' : '100vw !important';
          case 'recording':
            return props.size ? '100vw !important' : '100vw !important';
          case 'l':
            return props.size ? '100vw !important' : '100vw !important';
          case 'm':
            return props.size ? '100vw !important' : '100vw !important';
          case 'xs':
            return props.size ? 'calc(100vw - 2rem)' : '47.5rem';
          case 'xxs':
            return props.size ? 'calc(100vw - 2rem)' : '47.5rem';
          case 'push':
            return props.size ? '100%' : '47.5rem';
          default:
            return '42.5rem';
        }
      } else {
        return '42.5rem';
      }
    }};
    min-height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'import-clients':
            return props.size ? '100vh !important' : '100vh !important';
          case 'recording':
            return props.size ? '100vh !important' : '100vh !important';
          case 'l':
            return props.size ? '100vh !important' : '100vh !important';
          case 'm':
            return props.size ? '100vh !important' : '100vh !important';
          case 'xs':
            return props.size ? 'auto' : '47.5rem';
          case 'xxs':
            return props.size ? 'auto' : '47.5rem';
          case 'push':
            return props.size ? 'auto' : '47.5rem';

          default:
            return '42.5rem';
        }
      } else {
        return '42.5rem';
      }
    }};
  }
`;

export const ModalContent = styled.div<IModalContent>`
  box-shadow: ${SHADOW_MODAL};
  padding: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'new-sales':
          return props.size ? '24px' : '24px';
        case 'time-table':
          return props.size ? '1.5rem' : '2rem';
        case 'note-creator':
          return props.size ? '24px' : '24px';
        case 'messenger-editor':
          return props.size ? '28px' : '28px';
        // case 'category-creator':
        //   return props.size ? '24px' : '24px';
        default:
          return '';
      }
    } else {
      return '';
    }
  }};

  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'note-creator':
          return props.size ? '460px' : '460px';
        case 'messenger-editor':
          return props.size ? '448px' : '448px';
        case 'category-creator':
          return props.size ? '755px' : '755px';
        case 'telegram-bot':
          return props.size ? '794px' : '794px';
        default:
          return '100%';
      }
    } else {
      return '100%';
    }
  }};
  max-width: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'import-clients':
          return props.size ? 'fit-content' : 'fit-content';
        case 'recording':
          return props.size ? '85rem' : '85rem';
        case 'l':
          return props.size ? '65rem' : '42.5rem';
        case 'm':
          return props.size ? '42.5rem' : '42.5rem';
        case 'xs':
          return props.size ? '38.25rem' : '42.5rem';
        case 'newxxs':
          return props.size ? '432px' : '42.5rem';
        case 'confirm-delete':
          return props.size ? '400px' : '42.5rem';
        case 'push':
          return props.size ? '100%' : '42.5rem';
        case 'time-table':
          return props.size ? '42.5rem' : '42.5rem';
        case 'note-creator':
          return props.size ? '460px' : '47.5rem';
        case 'messenger-editor':
          return props.size ? '448px' : '47.5rem';
        case 'register-time-table':
          return props.size ? '570px' : '47.5rem';
        case 'category-creator':
          return props.size ? '755px' : '47.5rem';
        case 'telegram-bot':
          return props.size ? '794px' : '794px';
        case 'cash-register-icon':
          return props.size ? '71rem' : '47.5rem';
        default:
          return '42.5rem';
      }
    } else {
      return '42.5rem';
    }
  }};

  background: ${(props) => props.theme.color.elevation01};
  z-index: 8;
  overflow: hidden;
  transition: 0.3s ease;
  border-radius: 8px;
  pointer-events: auto;
  opacity: 1;
  animation-duration: ${DURATION};
  animation-timing-function: ease-in-out;
  animation-name: ${translateYIn};
  &.isClosing {
    animation-duration: ${DURATION};
    transition-duration: ${DURATION};
    transition-property: visibility;
    transition-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-name: ${translateYOut};
    visibility: hidden;
    *,
    *:before,
    *:after {
      transition-property: visibility;
      transition-duration: ${DURATION};

      transition-timing-function: ease-in-out;
      visibility: hidden;
      opacity: 0;
    }
  }
  &.side {
    max-width: 30rem;
    height: 100%;
    animation-name: ${translateXIn};
    width: 30rem;
    overflow: auto;
    border-radius: 12px 0 0 12px;
    @media (max-width: 767px) {
      animation-name: ${translateYIn};
      max-width: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'recording':
              return props.size ? '100vw !important' : '100vw !important';
            case 'l':
              return props.size ? '100vw !important' : '100vw !important';
            case 'm':
              return props.size ? '100vw !important' : '100vw !important';
            case 'xs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            case 'xxs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            default:
              return '100vw';
          }
        } else {
          return '100vw';
        }
      }};
      width: 100%;
    }

    box-shadow: ${SHADOW};
    &.isClosing {
      animation-name: ${translateXOut};
      @media (max-width: 767px) {
        animation-name: ${translateYOut};
      }
    }
  }
  &.side-transparent:has(.import-info) {
    background-color: ${themelight.newColor.grey07};
  }
  &.side-transparent {
    max-width: 23rem;
    height: 100%;
    animation-name: ${translateXIn};
    width: 23rem;
    overflow: auto;
    @media (max-width: 767px) {
      animation-name: ${translateYIn};
      max-width: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'recording':
              return props.size ? '100vw !important' : '100vw !important';
            case 'l':
              return props.size ? '100vw !important' : '100vw !important';
            case 'm':
              return props.size ? '100vw !important' : '100vw !important';
            case 'xs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            case 'xxs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            default:
              return '100vw';
          }
        } else {
          return '100vw';
        }
      }};
      width: 100%;
    }
    box-shadow: ${SHADOW};
    &.isClosing {
      animation-name: ${translateXOut};
      @media (max-width: 767px) {
        animation-name: ${translateYOut};
      }
    }
  }
  &.side-overlay {
    max-width: 27rem;
    width: 27rem;
    overflow-y: auto;
    padding: 0;
    border-radius: 0;
    border-top-left-radius: 1rem;
    @media (max-width: 767px) {
      max-width: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'recording':
              return props.size ? '100vw !important' : '100vw !important';
            case 'l':
              return props.size ? '100vw !important' : '100vw !important';
            case 'm':
              return props.size ? '100vw !important' : '100vw !important';
            case 'xs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            case 'xxs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            default:
              return '100vw';
          }
        } else {
          return '100vw';
        }
      }};
      width: 100%;
    }
    > div {
      height: 100%;
      padding: 2rem;
      @media (max-width: 767px) {
        padding: 0 1rem 1rem;
      }
    }
  }
  &.side-overlay-transparent {
    max-width: 30.25rem;
    width: 30.25rem;
    overflow-y: auto;
    padding: 0;
    border-radius: 0;
    border-top-left-radius: 1rem;
    box-shadow: -9px 0px 28px 0px rgba(219, 216, 219, 1);
    @media (max-width: 767px) {
      max-width: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'recording':
              return props.size ? '100vw !important' : '100vw !important';
            case 'l':
              return props.size ? '100vw !important' : '100vw !important';
            case 'm':
              return props.size ? '100vw !important' : '100vw !important';
            case 'xs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            case 'xxs':
              return props.size ? 'calc(100vw - 2rem)' : '100vw';
            default:
              return '100vw';
          }
        } else {
          return '100vw';
        }
      }};
      width: 100%;
    }
    > div {
      height: 100%;
      padding: 1rem 1.5rem;
      @media (max-width: 767px) {
        padding: 0 1rem 1rem;
      }
    }
  }
  &. @media (max-width: 767px) {
    border: none;
    background: ${(props) => props.size === 'xxs' && 'transparent'};
    box-shadow: ${(props) => (props.size === 'xxs' ? 'none' : SHADOW_MODAL_MOBILE)};

    padding: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'recording':
            return props.size ? '0 1rem 1rem' : '0 1rem 1rem';
          case 'l':
            return props.size ? '0 1rem 1rem' : '0 1rem 1rem';
          case 'm':
            return props.size ? '0 1rem 1rem' : '0 1rem 1rem';
          case 'xs':
            return props.size ? '1rem' : '0 1rem 1rem';
          case 'xxs':
            return props.size ? '0' : '0 1rem 1rem';
          case 'push':
            return props.size ? '2rem' : '0 1rem 1rem';
          default:
            return '0 1rem 1rem';
        }
      } else {
        return '0 1rem 1rem';
      }
    }};
    width: 100vw;
    margin: 0 auto;
    max-width: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'recording':
            return props.size ? '100vw !important' : '100vw !important';
          case 'l':
            return props.size ? '100vw !important' : '100vw !important';
          case 'm':
            return props.size ? '100vw !important' : '100vw !important';
          case 'xs':
            return props.size ? 'calc(100vw - 2rem)' : '100vw';
          case 'xxs':
            return props.size ? 'calc(100vw - 2rem)' : '100vw';
          case 'push':
            return props.size ? '100%' : '100vw';
          default:
            return '100vw';
        }
      } else {
        return '100vw';
      }
    }};
    min-height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'recording':
            return props.size ? '100vh !important' : '100vh !important';
          case 'l':
            return props.size ? '100vh !important' : '100vh !important';
          case 'm':
            return props.size ? '100vh !important' : '100vh !important';
          case 'xs':
            return props.size ? 'auto' : '100vh !important';
          case 'xxs':
            return props.size ? 'auto' : '100vh !important';
          case 'push':
            return props.size ? 'auto' : '100vh !important';
          default:
            return '100vh !important';
        }
      } else {
        return '100vh !important';
      }
    }};
    border-radius: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'recording':
            return props.size ? '0' : '0';
          case 'l':
            return props.size ? '0' : '0';
          case 'm':
            return props.size ? '0' : '0';
          case 'xs':
            return props.size ? '1rem' : '0';
          case 'xxs':
            return props.size ? '0' : '0';
          default:
            return '0';
        }
      } else {
        return '0';
      }
    }};
    bottom: 0;
  }
`;

export const CloseButton = styled.button<IModalContent>`
  background: rgba(104, 104, 104, 0.96);
  display: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'note-creator':
          return props.size ? 'none' : 'none';
        case 'messenger-editor':
          return props.size ? 'none' : 'none';
        default:
          return 'flex';
      }
    } else {
      return 'flex';
    }
  }};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 1.5rem;
  border: none;
  cursor: pointer;
  outline: none;
  right: 1.5rem;
  animation-duration: ${DURATION_500};
  animation-timing-function: ease-in-out;
  animation-name: ${fadeIn};
  opacity: 1;
  color: ${(props) => props.theme.color.base01};
  height: ${HEIGHT_S};
  width: ${HEIGHT_S};
  border-radius: 6.25rem;
  z-index: ${Z_INDEX};
  transition-duration: ${DURATION};
  transition-property: background;
  transition-timing-function: ease-in-out;
  &:hover {
    background: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'note-creator':
            return props.size ? 'none' : 'none';
          case 'messenger-editor':
            return props.size ? 'none' : 'none';
          default:
            return 'rgba(159, 159, 159, 0.86)';
        }
      } else {
        return 'rgba(159, 159, 159, 0.86)';
      }
    }};
  }
  &.isClosing {
    opacity: 0;
    transition-duration: ${DURATION};
    transition-property: opacity;
    transition-timing-function: ease-in-out;
  }
  &.side {
    top: 175px;
    @media (max-width: 767px) {
      top: 1.5rem;
    }
  }
  &.side-transparent {
    top: 175px;
    @media (max-width: 767px) {
      top: 1.5rem;
    }
  }

  &.side-overlay-transparent {
    top: 2rem;
  }
  &.side-overlay {
    top: 2rem;
  }
  &.side-recording {
    position: relative;
    top: auto;
  }
`;
export const SheetModalWrapper = styled.div`
  padding: 0 1rem 2rem;
  height: 100%;
  overflow-y: auto;
`;
