import React from 'react';

import { AddBreakIcon } from './actions/AddBreak';
import { BlackClose } from './actions/BlackClose';
import { ChangeIcon } from './actions/Change';
import { Circles } from './actions/Circles';
import { CirclesEmpty } from './actions/CirclesEmpty';
import { Close } from './actions/Close';
import { CloseFill } from './actions/CloseFill';
import { Copy } from './actions/Copy';
import { DeleteBreak } from './actions/DeleteBreak';
import { DeleteIcon } from './actions/DeleteIcon';
import { DoubleCheck } from './actions/DoubleCheck';
import { EditPen } from './actions/EditPen';
import { Filter } from './actions/Filter';
import { ImportFileIcon } from './actions/ImportFileIcon';
import { LogoMark } from './actions/LogoMark';
import { LogoMarkWhite } from './actions/LogoMarkWhite';
import { Menu } from './actions/Menu';
import { MinusFill } from './actions/MinusFill';
import { MoreHorizontalLines } from './actions/MoreHorizontalLines';
import { MoreVerticalOutline } from './actions/MoreVerticalOutline';
import { NewCalendarIcon } from './actions/NewCalendarIcon';
import { NewSend } from './actions/NewSend';
import { OptionsWithCircle } from './actions/OptionsWithCircle';
import { Pencil } from './actions/Pencil';
import { Print } from './actions/Print';
import { Question } from './actions/Question';
import { QuestionEmpty } from './actions/QuestionEmpty';
import { QuestionGrey } from './actions/QuestionGrey';
import { RecordingSquareEmpty } from './actions/RecordingSquareEmpty';
import { RecordingSquareLine } from './actions/RecordingSquareLine';
import { RecordingSquareQr } from './actions/RecordingSquareQr';
import { Send } from './actions/Send';
import { ShapePlus } from './actions/ShapePlus';
import { Smile } from './actions/Smile';
import { Sort } from './actions/Sort';
import { SortReverse } from './actions/SortReverse';
import { StarOutline } from './actions/StarOutline';
import { Success } from './actions/Success';
import { SuccessBlack } from './actions/SuccessBlack';
import { SuccessFill } from './actions/SuccessFill';
import { ThumbsDownOutline } from './actions/ThumbsDownOutline';
import { ThumbsUpOutline } from './actions/ThumbsUpOutline';
import { UserOutline } from './actions/UserOutline';
import { WarningFill } from './actions/WarningFill';
import { Workbench } from './actions/Workbench';
import { Arrow2SquarepathOutline } from './arrows/Arrow2SquarepathOutline';
import { ArrowCalendarNext } from './arrows/ArrowCalendarNext';
import { ArrowCalendarPrev } from './arrows/ArrowCalendarPrev';
import { ArrowDownOutline } from './arrows/ArrowDownOutline';
import { ArrowDownToSquareOutline } from './arrows/ArrowDownToSquareOutline';
import { ArrowEnterSquareOutline } from './arrows/ArrowEnterSquareOutline';
import { ArrowLeft } from './arrows/ArrowLeft';
import { ArrowLeftOutline } from './arrows/ArrowLeftOutline';
import { ArrowLeftOutline48 } from './arrows/ArrowLeftOutline48';
import { ArrowLeftSquareOutline } from './arrows/ArrowLeftSquareOutline';
import { ArrowRightCircleOutline } from './arrows/ArrowRightCircleOutline';
import { ArrowRightOutline } from './arrows/ArrowRightOutline';
import { ArrowRightSquareOutline } from './arrows/ArrowRightSquareOutline';
import { ArrowshapeLeft2outline } from './arrows/ArrowshapeLeft2outline';
import { ArrowTurnLeftOutline } from './arrows/ArrowTurnLeftOutline';
import { ArrowUpOutline } from './arrows/ArrowUpOutline';
import { ArrowUpRightOutline } from './arrows/ArrowUpRightOutline';
import { ArrowUturnLeftOutline } from './arrows/ArrowUturnLeftOutline';
import { ArrowUturnRightOutline } from './arrows/ArrowUturnRightOutline';
import { ChevronDownSmall } from './arrows/ChevronDownSmall';
import { ChevronLeftOutline } from './arrows/ChevronLeftOutline';
import { ChevronLeftSmall } from './arrows/ChevronLeftSmall';
import { ChevronLeftSquareOutline } from './arrows/ChevronLeftSquareOutline';
import { ChevronRightOutline } from './arrows/ChevronRightOutline';
import { ChevronRightSmall } from './arrows/ChevronRightSmall';
import { ChevronRightSquareOutline } from './arrows/ChevronRightSquareOutline';
import { ChevronUpSmall } from './arrows/ChevronUpSmall';
import { CloudArrowUpOutline } from './arrows/CloudArrowUpOutline';
import { CopyTimeTableArrow } from './arrows/CopyTimeTableArrow';
import { DocumentArrowUpOutline } from './arrows/DocumentArrowUpOutline';
import { DoorArrowLeftOutline } from './arrows/DoorArrowLeftOutline';
import { DoorArrowRightOutline } from './arrows/DoorArrowRightOutline';
import { DownIcon } from './arrows/DownIcon';
import { DownloadCheckOutline } from './arrows/DownloadCheckOutline';
import { DownloadCloudOutline } from './arrows/DownloadCloudOutline';
import { DownloadCrossBadgeOutline } from './arrows/DownloadCrossBadgeOutline';
import { DownloadOutline } from './arrows/DownloadOutline';
import { ExportIcon } from './arrows/ExportIcon';
import { ExternalLinkOutline } from './arrows/ExternalLinkOutline';
import { FolderSimpleArrowRight } from './arrows/FolderSimpleArrowRight';
import { FolderSimpleArrowUpOutline } from './arrows/FolderSimpleArrowUpOutline';
import { ImportIcon } from './arrows/ImportIcon';
import { LeftIcon } from './arrows/LeftIcon';
import { LinkCircle } from './arrows/LinkCircle';
import { NewBackArrow } from './arrows/NewBackArrow';
import { NewChevronUp } from './arrows/NewChevronUp';
import { NewDownLoadIcon } from './arrows/NewDownLoadIcon';
import { RefreshOutline } from './arrows/RefreshOutline';
import { Repeat1Outline } from './arrows/Repeat1Outline';
import { RepeatOutline } from './arrows/RepeatOutline';
import { ReplayOutline } from './arrows/ReplayOutline';
import { ReplyOutline } from './arrows/ReplyOutline';
import { RightWithSquareIcon } from './arrows/RightWithSquareIcon';
import { ShareExternalOutline } from './arrows/ShareExternalOutline';
import { ShareOutline } from './arrows/ShareOutline';
import { ShuffleOutline } from './arrows/ShuffleOutline';
import { SortOutline } from './arrows/SortOutline';
import { StatisticsOutline } from './arrows/StatisticsOutline';
import { UnarchiveOutline } from './arrows/UnarchiveOutline';
import { UploadOutline } from './arrows/UploadOutline';
import { EditHumanIcon } from './human/EditHumanIcon';
import { EmployesIcon } from './human/EmployesIcon';
import { EmptyHumanIcon } from './human/EmptyHumanIcon';
import { FunnyGhost } from './human/funny-ghost';
import { ProfileUser } from './human/ProfileUser';
import { TempAvatarIcon } from './human/TempAvatarIcon';
import { UserAdded } from './human/UserAdded';
import { UserAddedOutline } from './human/UserAddedOutline';
import { UserDropdown } from './human/UserDropdown';
import { UserFilled } from './human/UserFilled';
import { UserIcon } from './human/UserIcon';
import { UsersIcon } from './human/UsersIcon';
import { AnalyticIcon } from './icons/AnalyticIcon';
import { ArrowClients } from './icons/ArrowClients';
import { ArrowRightBot } from './icons/ArrowRightBot';
import { Baloon } from './icons/Baloon';
import { BaloonTransparent } from './icons/BaloonTransparent';
import { BellIcon } from './icons/BellIcon';
import { CalendarIcon } from './icons/CalendarIcon';
import { CalendarRecording } from './icons/CalendarRecording';
import { CartIcon } from './icons/CartIcon';
import { CartTarifIcon } from './icons/CartTarifIcon';
import { ChatIcon } from './icons/ChatIcon';
import { CheckboxIcon } from './icons/CheckboxIcon';
import { ClockIcon } from './icons/ClockIcon';
import { DocumentIcon } from './icons/DocumentIcon';
import { EmployeesTwo } from './icons/EmployeesTwo';
import { EmptyPhoto } from './icons/EmptyPhoto';
import { ExitIcon } from './icons/ExitIcon';
import { FileIcon } from './icons/FileIcon';
import { FilialIcon } from './icons/FilialIcon';
import { FinansIcon } from './icons/FinansIcon';
import { GiftIcon } from './icons/GiftIcon';
import { GListLi } from './icons/GListLi';
import { GrayPlus } from './icons/GrayPlus';
import { GrayX } from './icons/GrayX';
import { HomeIcon } from './icons/HomeIcon';
import { InfoIcon } from './icons/InfoIcon';
import { LogoFirstIcon } from './icons/LogoFirstIcon';
import { LogoIcon } from './icons/LogoIcon';
import { ManagementIcon } from './icons/ManagementIcon';
import { MarketingIcon } from './icons/MarketingIcon';
import { MessageIcon } from './icons/MessageIcon';
import { ModuleSettingsIcon } from './icons/ModuleSettingsIcon';
import { MoonIcon } from './icons/MoonIcon';
import { OpenOptionsIcon } from './icons/OpenOptionsIcon';
import { PayIcon } from './icons/PayIcon';
import { PaymentAddIcon } from './icons/PaymentAddIcon';
import { PayOutlineIcon } from './icons/PayOutlineIcon';
import { PhoneDop } from './icons/PhoneDop';
import { PhoneIcon } from './icons/PhoneIcon';
import { PostIcon } from './icons/PostIcon';
import { ProcentIcon } from './icons/ProcentIcon';
import { RecordingIcon } from './icons/RecordingIcon';
import { RemittanceIcon } from './icons/RemittanceIcon';
import { SchedulePlus } from './icons/SchedulePlus';
import { SearchInputIcon } from './icons/SearchInputIcon';
import { SearchModalIcon } from './icons/SearchModalIcon';
import { SettingPortalIcon } from './icons/SettingPortalIcon';
import { StarIcon } from './icons/StarIcon';
import { StarService } from './icons/StarService';
import { StarTarifIcon } from './icons/StarTarifIcon';
import { SuccessOutlineIcon } from './icons/SuccessOutlineIcon';
import { SwitchIcon } from './icons/SwitchIcon';
import { TabIcon } from './icons/TabIcon';
import { TasksIcon } from './icons/TasksIcon';
import { TelegramBlue } from './icons/TelegramBlue';
import { TicketIcon } from './icons/TicketIcon';
import { TListLi } from './icons/TListLi';
import { TrashIcon } from './icons/TrashIcon';
import { TrashRedIcon } from './icons/TrashRedIcon';
import { WalletIcon } from './icons/WalletIcon';
import { TapBarChat } from './mobile/TapBarChat';
import { TapBarChatGray } from './mobile/TapBarChatGray';
import { TapBarMenu } from './mobile/TapBarMenu';
import { TapBarMenuGray } from './mobile/TapBarMenuGray';
import { TapBarRecording } from './mobile/TapBarRecording';
import { TapBarRecordingGray } from './mobile/TapBarRecordingGray';
import { TapBarSchedule } from './mobile/TapBarSchedule';
import { TapBarScheduleGray } from './mobile/TapBarScheduleGray';
import { BillheadOutline } from './objects/BillheadOutline';
import { BookSpreadOutline } from './objects/BookSpreadOutline';
import { CalendarAddOutline } from './objects/CalendarAddOutline';
import { CheckShieldOutline } from './objects/CheckShieldOutline';
import { CompanyGray } from './objects/CompanyGray';
import { ComputerOutline } from './objects/ComputerOutline';
import { CrossShieldOutline } from './objects/CrossShieldOutline';
import { MarketOutline } from './objects/MarketOutline';
import { MoneyRequestOutline } from './objects/MoneyRequestOutline';
import { MoneySendOutline } from './objects/MoneySendOutline';
import { Planet } from './objects/Planet';
import { Product } from './objects/Product';
import { Services2 } from './objects/Services2';
import { SquaresTwo } from './objects/SquaresTwo';
import { Category } from './products/Category';
import { DayIcon } from './products/DayIcon';
import { ListIcon } from './products/ListIcon';
import { ListRecording } from './products/ListRecording';
import { ProductsMenuIcon } from './products/ProductsMenuIcon';
import { Schedule } from './products/Schedule';
import { Service } from './products/Service';
import { WeekIcon } from './products/WeekIcon';
import { AdditionalSettings } from './settings/AdditionalSettings';
import { BusinessSettings } from './settings/BusinessSettings';
import { CashBox } from './settings/CashBox';
import { ColorBigIcon } from './settings/ColorBigIcon';
import { ColorIcon } from './settings/ColorIcon';
import { DeleteService } from './settings/DeleteService';
import { EmptyImg } from './settings/EmptyImg';
import { OnlineRecord } from './settings/OnlineRecord';
import { PersonalArea } from './settings/PersonalArea';
import { PortalTelebon } from './settings/PortalTelebon';
import { SettingUpService } from './settings/SettingUpService';
import { SubscriptionAndBilling } from './settings/SubscriptionAndBilling';
import { AppointmentsIcon } from './sidebar/AppointmentsIcon';
import { ChatsIcon } from './sidebar/ChatsIcon';
import { ClientsIcon } from './sidebar/ClientsIcon';
import { EmployeesIcon } from './sidebar/EmployeesIcon';
import { PaymentsIcon } from './sidebar/PaymentsIcon';
import { ProductsIcon } from './sidebar/ProductsIcon';
import { ProfileIcon } from './sidebar/ProfileIcon';
import { SettingIcon } from './sidebar/SettingIcon';
import { SidebarAnalytics } from './sidebar/SidebarAnalytics';
import { SidebarSchedule } from './sidebar/SidebarSchedule';
import { SidebarTasks } from './sidebar/SidebarTasks';
import { TelebonIcon } from './sidebar/TelebonIcon';
import { DisabledIcon } from './signs/DisabledIcon';
import { Megafon } from './signs/Megafon';
import { MinusIcon } from './signs/MinusIcon';
import { MinusOutlineIcon } from './signs/MinusOutlineIcon';
import { NotifyError } from './signs/NotifyError';
import { NotifySuccess } from './signs/NotifySuccess';
import { PlusChatIcon } from './signs/PlusChatIcon';
import { PlusIcon } from './signs/PlusIcon';
import { PlusOutlineIcon } from './signs/PlusOutlineIcon';
import { PlusSquareIcon } from './signs/PlusSquareIcon';
import { WarningIcon } from './signs/WarningIcon';
import { SmallCalendar } from './small-icons/SmallCalendar';
import { SmallEditIcon } from './small-icons/SmallEditIcon';
import { SmallInfo } from './small-icons/SmallInfo';
import { SmallTrashIcon } from './small-icons/SmallTrashIcon';
import { AvitoIcon } from './social/AvitoIcon';
import { Chein } from './social/Chein';
import { InstagramIcon } from './social/InstagramIcon';
import { MailStackOutline } from './social/MailStackOutline';
import { NewChat } from './social/NewChat';
import { SiteBigIcon } from './social/SiteBigIcon';
import { SiteIcon } from './social/SiteIcon';
import { SupportIcon } from './social/SupportIcon';
import { SupportIcon20 } from './social/SupportIcon20';
import { TelegramEmptyIcon } from './social/TelegramEmptyIcon';
import { TelegramIcon } from './social/TelegramIcon';
import { TelegramIcon20 } from './social/TelegramIcon20';
import { TelegramNew } from './social/TelegramNew';
import { ViberIcon } from './social/ViberIcon';
import { VkIcon } from './social/VKIcon';
import { VkNew } from './social/VkNew';
import { ChosenTimeCell } from './timetable/ChosenTimeCell';
import { EditTimeCell } from './timetable/EditTimeCell';
import { AllCategoriesOutline } from './unsorted/AllCategoriesOutline';
import { ArrowBack } from './unsorted/ArrowBack';
import { BackgroundBorRecording } from './unsorted/BackgroundBorRecording';
import { BellBlueWhite } from './unsorted/BellBlueWhite';
import { BreakBusket } from './unsorted/BreakBusket';
import { CloudBlueWhite } from './unsorted/CloudBlueWhite';
import { EmptyTransactions } from './unsorted/EmptyTransactions';
import { NotFoundError } from './unsorted/NotFoundError';
import { OkBlueWhite } from './unsorted/OkBlueWhite';
import { PhoneSignalWifiBatery } from './unsorted/PhoneSignalWifiBatery';
import { PointerIcon } from './unsorted/PointerIcon';
import { ProfileUserRound } from './unsorted/ProfileUserRound';
import { RegArrowRight } from './unsorted/RegArrowRight';
import { RoundCheckIcon } from './unsorted/RoundCheckIcon';
import { RoundClose } from './unsorted/RoundClose';
import { TelegramForBackground } from './unsorted/TelegramForBackground';
import { Tooltip } from './unsorted/Tooltip';
import { WarningRound } from './unsorted/WarningRound';

import { LogoTLBN } from '../LogoTLBN';
import { WhatsUpIcon } from '../social/WhatsUpIcon';
import { ArrowRight } from './arrows/ArrowRight';
import { ArrowBackNewIcon } from './icons/ArrowBackNewIcon';
import { ArrowNextNewIcon } from './icons/ArrowNextNewIcon';
import { ArrowDownNewIcon } from './icons/ArrowDownNewIcon';
import { ContextMenuIcon } from './icons/ContextMenuIcon';
import { AvatarIcon } from './icons/AvatarIcon';

export enum EIcon {
  arrowright,
  notfounddesktop,
  profileuserround,
  roundclose,
  roundcheck,
  tempavatar,
  okbluewhite,
  cloudbluewhite,
  bellbluewhite,
  baloontransparent,
  starservice,
  baloon,
  telegramblue,
  tlistli,
  glistli,
  pluschat,
  scheduleplus,
  arrowclients,
  sort,
  sortreverse,
  arrow2squarepathoutline,
  listrecording,
  companygray,
  notifysuccess,
  products,
  telebon,
  sidebaranalytics,
  clients,
  tapbarchatgray,
  tapbarmenugray,
  tapbarrecordinggray,
  tapbarschedulegray,
  profile,
  employees,
  payments,
  chats,
  setting,
  appointments,
  sidebarschedule,
  sidebartasks,
  calendaraddoutline,
  tapbarrecording,
  tapbarchat,
  tapbarmenu,
  tapbarschedule,
  funnyghost,
  services2,
  chein,
  profileuser,
  day,
  backgroundborrecording,
  list,
  week,
  disabled,
  megafon,
  cartTarif,
  starTarif,
  moon,
  squaretwo,
  product,
  smallinfo,
  menu,
  calendarrecording,
  doublecheck,
  send,
  newsend,
  smile,
  telegramempty,
  questionempty,
  questiongrey,
  success,
  successblack,
  circlesempty,
  morehorizontallines,
  warningfill,
  smallcalendar,
  userfilled,
  category,
  shapeplus,
  circles,
  service,
  question,
  tasks,
  schedule,
  checkshieldoutline,
  computeroutline,
  crossshieldoutline,
  mailstackoutline,
  marketoutline,
  useraddedoutline,
  arrowleft,
  arrowdown,
  tooltip,
  arrowrightwithsquare,
  paymentadd,
  tab,
  calendar,
  cart,
  clock,
  minusoutline,
  minus,
  payoutline,
  phone,
  plus,
  plusoutline,
  plussquare,
  post,
  procent,
  star,
  succesoutline,
  trash,
  trashred,
  pointer,
  user,
  users,
  warning,
  searchmodal,
  smalltrash,
  smalledit,
  penedit,
  productsmenu,
  edithuman,
  import,
  export,
  analytic,
  chat,
  finans,
  home,
  management,
  recordingsettings,
  settingprotal,
  settings,
  logo,
  logofirst,
  file,
  filial,
  ticket,
  message,
  telegram,
  watsapp,
  viber,
  phonedop,
  switch,
  thumbsdownoutline,
  thumbsupoutline,
  modulesettings,
  moreverticaloutline,
  pencil,
  arrowdownoutline,
  arrowdowntosquareoutline,
  arrowentersquareoutline,
  arrowleftoutline,
  arrowleftsquareoutline,
  arrowrightcircleoutline,
  arrowback,
  arrowrightoutline,
  arrowupoutline,
  arrowrightsquareoutline,
  arrowturnleftoutline,
  arrowuprightoutline,
  arrowuturnleftoutline,
  arrowuturnrightoutline,
  chevrondownsmall,
  chevronleftoutline,
  chevronleftsmall,
  chevronleftsquareoutline,
  chevronrightoutline,
  chevronrightsmall,
  chevronrightsquareoutline,
  chevronupsmall,
  cloudarrowupoutline,
  documentarrowupoutline,
  doorarrowleftoutline,
  doorarrowrightoutline,
  downloadcheckoutline,
  downloadcloudoutline,
  downloadcrossbadgeoutline,
  downloadoutline,
  externallinkoutline,
  foldersimplearrowright,
  foldersimplearrowupoutline,
  linkcircle,
  refreshoutline,
  repeat1outline,
  repeatoutline,
  replayoutline,
  replyoutline,
  shareexternaloutline,
  shareoutline,
  shuffleoutline,
  sortoutline,
  statisticsoutline,
  unarchiveoutline,
  uploadoutline,
  importfile,
  billheadoutline,
  bookspreadoutline,
  moneyrequestoutline,
  moneysendoutline,
  allcategoriesoutline,
  logomark,
  logomarkwhite,
  optionswithcircle,
  recordingsquareempty,
  recordingsquareline,
  recordingsquareqr,
  useroutline,
  staroutline,
  close,
  blackclose,
  arrowshapeleft2outline,
  useradded,
  closefill,
  minusfill,
  successfill,
  workbench,
  arrowcalendarnext,
  arrowcalendarprev,
  userdropdown,
  deleteicon,
  supporticon,
  avitosocial,
  vksocial,
  newchat,
  planet,
  pay,
  marketing,
  notifyerror,
  filter,
  supporticon20,
  telegram20,
  logotlbn,
  arrowleftoutline48,
  arrowleftreg,
  arrowrightbot,
  regarrowright,
  grayplus,
  grayx,
  breakbusket,
  edittimecell,
  chosentimecell,
  copy,
  print,
  change,
  deletebreak,
  addbreak,
  checkbox,
  search,
  copytimetablearrow,
  employeesicon,
  additionalsettings,
  businesssettings,
  cashbox,
  onlinerecord,
  personalarea,
  portaltelebon,
  settingupservice,
  subscriptionandbilling,
  newBackArrow,
  emptyimg,
  coloricon,
  colorbigicon,
  deleteservice,
  instagram,
  telegramnew,
  vknew,
  sitenew,
  emptyphoto,
  calendarnew,
  chevronupnew,
  sitebig,
  telegramforbackground,
  phonesignalwifibatery,
  employeestwo,
  emptyhumanicon,
  warningRound,
  infoicon,
  documenticon,
  exiticon,
  emptytransactions,
  walleticon,
  remittanceicon,
  newdownloadicon,
  openoptionsicon,
  gifticon,
  bellicon,
  arrowbacknew,
  arrownextnew,
  arrowdownnew,
  contextmenuicon,
  avataricon
}

const ICONS: Record<EIcon, any> = {
  [EIcon.arrowright]: ArrowRight,
  [EIcon.notfounddesktop]: NotFoundError,
  [EIcon.roundcheck]: RoundCheckIcon,
  [EIcon.pointer]: PointerIcon,
  [EIcon.warningRound]: WarningRound,
  [EIcon.profileuserround]: ProfileUserRound,
  [EIcon.roundclose]: RoundClose,
  [EIcon.tempavatar]: TempAvatarIcon,
  [EIcon.okbluewhite]: OkBlueWhite,
  [EIcon.cloudbluewhite]: CloudBlueWhite,
  [EIcon.bellbluewhite]: BellBlueWhite,
  [EIcon.baloontransparent]: BaloonTransparent,
  [EIcon.employeestwo]: EmployeesTwo,
  [EIcon.starservice]: StarService,
  [EIcon.phonesignalwifibatery]: PhoneSignalWifiBatery,
  [EIcon.tooltip]: Tooltip,
  [EIcon.telegramforbackground]: TelegramForBackground,
  [EIcon.arrowrightbot]: ArrowRightBot,
  [EIcon.arrowback]: ArrowBack,
  [EIcon.baloon]: Baloon,
  [EIcon.telegramblue]: TelegramBlue,
  [EIcon.tlistli]: TListLi,
  [EIcon.glistli]: GListLi,
  [EIcon.scheduleplus]: SchedulePlus,
  [EIcon.arrow2squarepathoutline]: Arrow2SquarepathOutline,
  [EIcon.sidebaranalytics]: SidebarAnalytics,
  [EIcon.companygray]: CompanyGray,
  [EIcon.clients]: ClientsIcon,
  [EIcon.profile]: ProfileIcon,
  [EIcon.telebon]: TelebonIcon,
  [EIcon.products]: ProductsIcon,
  [EIcon.employees]: EmployeesIcon,
  [EIcon.tapbarchatgray]: TapBarChatGray,
  [EIcon.tapbarmenugray]: TapBarMenuGray,
  [EIcon.tapbarrecordinggray]: TapBarRecordingGray,
  [EIcon.tapbarschedulegray]: TapBarScheduleGray,
  [EIcon.payments]: PaymentsIcon,
  [EIcon.chats]: ChatsIcon,
  [EIcon.setting]: SettingIcon,
  [EIcon.appointments]: AppointmentsIcon,
  [EIcon.sidebarschedule]: SidebarSchedule,
  [EIcon.sidebartasks]: SidebarTasks,
  [EIcon.logomark]: LogoMark,
  [EIcon.tapbarchat]: TapBarChat,
  [EIcon.tapbarschedule]: TapBarSchedule,
  [EIcon.tapbarrecording]: TapBarRecording,
  [EIcon.megafon]: Megafon,
  [EIcon.doublecheck]: DoubleCheck,
  [EIcon.product]: Product,
  [EIcon.telegramempty]: TelegramEmptyIcon,
  [EIcon.planet]: Planet,
  [EIcon.service]: Service,
  [EIcon.funnyghost]: FunnyGhost,
  [EIcon.circlesempty]: CirclesEmpty,
  [EIcon.questionempty]: QuestionEmpty,
  [EIcon.questiongrey]: QuestionGrey,
  [EIcon.marketing]: MarketingIcon,
  [EIcon.send]: Send,
  [EIcon.newsend]: NewSend,
  [EIcon.disabled]: DisabledIcon,
  [EIcon.smile]: Smile,
  [EIcon.tapbarmenu]: TapBarMenu,
  [EIcon.shapeplus]: ShapePlus,
  [EIcon.category]: Category,
  [EIcon.schedule]: Schedule,
  [EIcon.question]: Question,
  [EIcon.circles]: Circles,
  [EIcon.calendarrecording]: CalendarRecording,
  [EIcon.squaretwo]: SquaresTwo,
  [EIcon.tasks]: TasksIcon,
  [EIcon.morehorizontallines]: MoreHorizontalLines,
  [EIcon.logomarkwhite]: LogoMarkWhite,
  [EIcon.smallcalendar]: SmallCalendar,
  [EIcon.success]: Success,
  [EIcon.successblack]: SuccessBlack,
  [EIcon.optionswithcircle]: OptionsWithCircle,
  [EIcon.recordingsquareline]: RecordingSquareLine,
  [EIcon.recordingsquareempty]: RecordingSquareEmpty,
  [EIcon.recordingsquareqr]: RecordingSquareQr,
  [EIcon.notifysuccess]: NotifySuccess,
  [EIcon.notifyerror]: NotifyError,
  [EIcon.useroutline]: UserOutline,
  [EIcon.moon]: MoonIcon,
  [EIcon.day]: DayIcon,
  [EIcon.list]: ListIcon,
  [EIcon.week]: WeekIcon,
  [EIcon.listrecording]: ListRecording,
  [EIcon.backgroundborrecording]: BackgroundBorRecording,
  [EIcon.arrowleft]: LeftIcon,
  [EIcon.sort]: Sort,
  [EIcon.sortreverse]: SortReverse,
  [EIcon.profileuser]: ProfileUser,
  [EIcon.warningfill]: WarningFill,
  [EIcon.userfilled]: UserFilled,
  [EIcon.calendaraddoutline]: CalendarAddOutline,
  [EIcon.checkshieldoutline]: CheckShieldOutline,
  [EIcon.computeroutline]: ComputerOutline,
  [EIcon.crossshieldoutline]: CrossShieldOutline,
  [EIcon.mailstackoutline]: MailStackOutline,
  [EIcon.marketoutline]: MarketOutline,
  [EIcon.useraddedoutline]: UserAddedOutline,
  [EIcon.arrowdown]: DownIcon,
  [EIcon.arrowrightwithsquare]: RightWithSquareIcon,
  [EIcon.arrowdownoutline]: ArrowDownOutline,
  [EIcon.chein]: Chein,
  [EIcon.arrowdowntosquareoutline]: ArrowDownToSquareOutline,
  [EIcon.arrowentersquareoutline]: ArrowEnterSquareOutline,
  [EIcon.arrowleftoutline]: ArrowLeftOutline,
  [EIcon.arrowleftsquareoutline]: ArrowLeftSquareOutline,
  [EIcon.arrowrightcircleoutline]: ArrowRightCircleOutline,
  [EIcon.arrowrightoutline]: ArrowRightOutline,
  [EIcon.arrowrightsquareoutline]: ArrowRightSquareOutline,
  [EIcon.arrowupoutline]: ArrowUpOutline,
  [EIcon.arrowturnleftoutline]: ArrowTurnLeftOutline,
  [EIcon.arrowuprightoutline]: ArrowUpRightOutline,
  [EIcon.arrowuturnleftoutline]: ArrowUturnLeftOutline,
  [EIcon.arrowuturnrightoutline]: ArrowUturnRightOutline,
  [EIcon.chevrondownsmall]: ChevronDownSmall,
  [EIcon.chevronleftoutline]: ChevronLeftOutline,
  [EIcon.chevronleftsmall]: ChevronLeftSmall,
  [EIcon.smallinfo]: SmallInfo,
  [EIcon.menu]: Menu,
  [EIcon.chevronleftsquareoutline]: ChevronLeftSquareOutline,
  [EIcon.chevronrightoutline]: ChevronRightOutline,
  [EIcon.chevronrightsmall]: ChevronRightSmall,
  [EIcon.chevronrightsquareoutline]: ChevronRightSquareOutline,
  [EIcon.chevronupsmall]: ChevronUpSmall,
  [EIcon.cloudarrowupoutline]: CloudArrowUpOutline,
  [EIcon.documentarrowupoutline]: DocumentArrowUpOutline,
  [EIcon.doorarrowleftoutline]: DoorArrowLeftOutline,
  [EIcon.doorarrowrightoutline]: DoorArrowRightOutline,
  [EIcon.downloadcheckoutline]: DownloadCheckOutline,
  [EIcon.downloadcloudoutline]: DownloadCloudOutline,
  [EIcon.downloadcrossbadgeoutline]: DownloadCrossBadgeOutline,
  [EIcon.downloadoutline]: DownloadOutline,
  [EIcon.externallinkoutline]: ExternalLinkOutline,
  [EIcon.foldersimplearrowright]: FolderSimpleArrowRight,
  [EIcon.foldersimplearrowupoutline]: FolderSimpleArrowUpOutline,
  [EIcon.linkcircle]: LinkCircle,
  [EIcon.refreshoutline]: RefreshOutline,
  [EIcon.repeat1outline]: Repeat1Outline,
  [EIcon.repeatoutline]: RepeatOutline,
  [EIcon.replayoutline]: ReplayOutline,
  [EIcon.replyoutline]: ReplyOutline,
  [EIcon.shareexternaloutline]: ShareExternalOutline,
  [EIcon.shareoutline]: ShareOutline,
  [EIcon.shuffleoutline]: ShuffleOutline,
  [EIcon.sortoutline]: SortOutline,
  [EIcon.statisticsoutline]: StatisticsOutline,
  [EIcon.unarchiveoutline]: UnarchiveOutline,
  [EIcon.uploadoutline]: UploadOutline,
  [EIcon.paymentadd]: PaymentAddIcon,
  [EIcon.tab]: TabIcon,
  [EIcon.calendar]: CalendarIcon,
  [EIcon.cart]: CartIcon,
  [EIcon.arrowclients]: ArrowClients,
  [EIcon.cartTarif]: CartTarifIcon,
  [EIcon.starTarif]: StarTarifIcon,
  [EIcon.clock]: ClockIcon,
  [EIcon.minusoutline]: MinusOutlineIcon,
  [EIcon.minus]: MinusIcon,
  [EIcon.payoutline]: PayOutlineIcon,
  [EIcon.pay]: PayIcon,
  [EIcon.phone]: PhoneIcon,
  [EIcon.plussquare]: PlusSquareIcon,
  [EIcon.plus]: PlusIcon,
  [EIcon.pluschat]: PlusChatIcon,
  [EIcon.plusoutline]: PlusOutlineIcon,
  [EIcon.post]: PostIcon,
  [EIcon.procent]: ProcentIcon,
  [EIcon.star]: StarIcon,
  [EIcon.succesoutline]: SuccessOutlineIcon,
  [EIcon.trash]: TrashIcon,
  [EIcon.trashred]: TrashRedIcon,
  [EIcon.user]: UserIcon,
  [EIcon.users]: UsersIcon,
  [EIcon.warning]: WarningIcon,
  [EIcon.searchmodal]: SearchModalIcon,
  [EIcon.smalledit]: SmallEditIcon,
  [EIcon.smalltrash]: SmallTrashIcon,
  [EIcon.productsmenu]: ProductsMenuIcon,
  [EIcon.import]: ImportIcon,
  [EIcon.export]: ExportIcon,
  [EIcon.edithuman]: EditHumanIcon,
  [EIcon.analytic]: AnalyticIcon,
  [EIcon.chat]: ChatIcon,
  [EIcon.finans]: FinansIcon,
  [EIcon.home]: HomeIcon,
  [EIcon.management]: ManagementIcon,
  [EIcon.recordingsettings]: RecordingIcon,
  [EIcon.settingprotal]: SettingPortalIcon,
  [EIcon.settings]: SettingIcon,
  [EIcon.logo]: LogoIcon,
  [EIcon.services2]: Services2,
  [EIcon.logofirst]: LogoFirstIcon,
  [EIcon.file]: FileIcon,
  [EIcon.filial]: FilialIcon,
  [EIcon.ticket]: TicketIcon,
  [EIcon.message]: MessageIcon,
  [EIcon.telegram]: TelegramIcon,
  [EIcon.watsapp]: WhatsUpIcon,
  [EIcon.viber]: ViberIcon,
  [EIcon.phonedop]: PhoneDop,
  [EIcon.switch]: SwitchIcon,
  [EIcon.thumbsdownoutline]: ThumbsDownOutline,
  [EIcon.thumbsupoutline]: ThumbsUpOutline,
  [EIcon.modulesettings]: ModuleSettingsIcon,
  [EIcon.moreverticaloutline]: MoreVerticalOutline,
  [EIcon.importfile]: ImportFileIcon,
  [EIcon.billheadoutline]: BillheadOutline,
  [EIcon.bookspreadoutline]: BookSpreadOutline,
  [EIcon.moneysendoutline]: MoneySendOutline,
  [EIcon.moneyrequestoutline]: MoneyRequestOutline,
  [EIcon.allcategoriesoutline]: AllCategoriesOutline,
  [EIcon.staroutline]: StarOutline,
  [EIcon.close]: Close,
  [EIcon.blackclose]: BlackClose,
  [EIcon.arrowshapeleft2outline]: ArrowshapeLeft2outline,
  [EIcon.useradded]: UserAdded,
  [EIcon.minusfill]: MinusFill,
  [EIcon.workbench]: Workbench,
  [EIcon.closefill]: CloseFill,
  [EIcon.successfill]: SuccessFill,
  [EIcon.arrowcalendarprev]: ArrowCalendarPrev,
  [EIcon.arrowcalendarnext]: ArrowCalendarNext,
  [EIcon.userdropdown]: UserDropdown,
  [EIcon.supporticon]: SupportIcon,
  [EIcon.deleteicon]: DeleteIcon,
  [EIcon.avitosocial]: AvitoIcon,
  [EIcon.vksocial]: VkIcon,
  [EIcon.newchat]: NewChat,
  [EIcon.filter]: Filter,
  [EIcon.supporticon20]: SupportIcon20,
  [EIcon.telegram20]: TelegramIcon20,
  [EIcon.logotlbn]: LogoTLBN,
  [EIcon.arrowleftoutline48]: ArrowLeftOutline48,
  [EIcon.penedit]: EditPen,
  [EIcon.arrowleftreg]: ArrowLeft,
  [EIcon.regarrowright]: RegArrowRight,
  [EIcon.grayplus]: GrayPlus,
  [EIcon.grayx]: GrayX,
  [EIcon.breakbusket]: BreakBusket,
  [EIcon.edittimecell]: EditTimeCell,
  [EIcon.chosentimecell]: ChosenTimeCell,
  [EIcon.copy]: Copy,
  [EIcon.print]: Print,
  [EIcon.change]: ChangeIcon,
  [EIcon.deletebreak]: DeleteBreak,
  [EIcon.addbreak]: AddBreakIcon,
  [EIcon.pencil]: Pencil,
  [EIcon.checkbox]: CheckboxIcon,
  [EIcon.search]: SearchInputIcon,
  [EIcon.copytimetablearrow]: CopyTimeTableArrow,
  [EIcon.employeesicon]: EmployesIcon,
  [EIcon.additionalsettings]: AdditionalSettings,
  [EIcon.businesssettings]: BusinessSettings,
  [EIcon.cashbox]: CashBox,
  [EIcon.onlinerecord]: OnlineRecord,
  [EIcon.personalarea]: PersonalArea,
  [EIcon.portaltelebon]: PortalTelebon,
  [EIcon.settingupservice]: SettingUpService,
  [EIcon.subscriptionandbilling]: SubscriptionAndBilling,
  [EIcon.newBackArrow]: NewBackArrow,
  [EIcon.emptyimg]: EmptyImg,
  [EIcon.coloricon]: ColorIcon,
  [EIcon.colorbigicon]: ColorBigIcon,
  [EIcon.deleteservice]: DeleteService,
  [EIcon.instagram]: InstagramIcon,
  [EIcon.telegramnew]: TelegramNew,
  [EIcon.vknew]: VkNew,
  [EIcon.sitenew]: SiteIcon,
  [EIcon.emptyphoto]: EmptyPhoto,
  [EIcon.calendarnew]: NewCalendarIcon,
  [EIcon.chevronupnew]: NewChevronUp,
  [EIcon.sitebig]: SiteBigIcon,
  [EIcon.emptyhumanicon]: EmptyHumanIcon,
  [EIcon.infoicon]: InfoIcon,
  [EIcon.documenticon]: DocumentIcon,
  [EIcon.exiticon]: ExitIcon,
  [EIcon.emptytransactions]: EmptyTransactions,
  [EIcon.walleticon]: WalletIcon,
  [EIcon.remittanceicon]: RemittanceIcon,
  [EIcon.newdownloadicon]: NewDownLoadIcon,
  [EIcon.openoptionsicon]: OpenOptionsIcon,
  [EIcon.gifticon]: GiftIcon,
  [EIcon.bellicon]: BellIcon,
  [EIcon.arrowbacknew]: ArrowBackNewIcon,
  [EIcon.arrownextnew]: ArrowNextNewIcon,
  [EIcon.arrowdownnew]: ArrowDownNewIcon,
  [EIcon.contextmenuicon]: ContextMenuIcon,
  [EIcon.avataricon]: AvatarIcon
};

interface IIconProps {
  name: EIcon;
}

export function IconNew(props: IIconProps) {
  const { name } = props;

  const ChosenIcon = ICONS[name];

  return <ChosenIcon />;
}
