import * as Yup from 'yup';

import { passwordRegex } from '../shared/lib/helpers/regex.helper';

const phoneRegExp = /^\+7 \d{3} \d{3}-\d{2}-\d{1}[^_]*$/;

export const validationSchema = Yup.object({
  email: Yup.string().email('Введите корректный email'),
  password: Yup.string().min(5, 'Пароль должен содержать минимум 5 символов'),

  confirmpassword: Yup.string().oneOf([Yup.ref('password')], 'Пароли должны совпадать'),
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера')
});

export const validationClientSchema = Yup.object({
  name: Yup.string().matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке'),
  city: Yup.string().matches(/^[А-Яа-яЁё\s-()+]+$/, 'Укажите город на русском языке')
});

export const validationTelegramToken = Yup.object({
  token: Yup.string().matches(/^(\d+):([A-Za-z0-9\-_]+)$/, 'Введите существующий токен')
});

export const validationClientAddSchema = Yup.object({
  email: Yup.string().email('Введите корректный email'),
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера'),
  shouldValidatePhone: Yup.boolean(),
  dopphone: Yup.string().when('shouldValidatePhone', {
    is: true,
    then: (validationClientAddSchema) =>
      validationClientAddSchema.test('phone-length', 'Некорректный формат номера', function (value) {
        if (!value) {
          return false;
        }
        if (value && value.length === 0) {
          return true;
        }

        return value.length === 16;
      })
  }),
  name: Yup.string().matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке')
});

export const validationEmployeeSchema = Yup.object({
  email: Yup.string().email('Введите корректный email').required('Введите email'),
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера'),
  fio: Yup.string().matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке'),
  idfilial: Yup.string().required('Выберите филиал')
});

export const validationProfileSchema = Yup.object({
  email: Yup.string().email('Введите корректный email').required('Введите email'),
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера'),
  fio: Yup.string().matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке'),
  position: Yup.string().matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке')
});

export const validationPasswordSchema = Yup.object({
  password: Yup.string().min(5, 'Введите минимум 5 символов').required('Введите пароль'),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
    .required('Введите пароль повторно')
});

export const validationServiceWithGroupSchema = Yup.object({
  category: Yup.string().required('Выберите категорию'),
  name: Yup.string().required('Введите название'),
  duration: Yup.number()
    .required('Введите длительность')
    .min(1, 'Минимальная длительность 1')
    .max(1440, 'Максимальная длительность 1440 минут'),
  seatsmin: Yup.number().min(2, 'Min вместимость 1').max(999, 'Max вместимость 999').required('Введите значение'),
  seatsmax: Yup.number()
    .min(2, 'Min вместимость 2')
    .max(999, 'Max вместимость 999')
    //@ts-ignore
    .when(['seatsmin'], (seatsmin: number, schema: Yup.NumberSchema<number | undefined>) => {
      return schema.min(seatsmin, 'Max вместимость должна быть больше или равна Min') as Yup.NumberSchema<number | undefined>;
    })
    .required('Введите значение')
});
export const validationRecordingModal = Yup.object({
  subproductId: Yup.string().required('Введите название'),
  productId: Yup.string().required('Введите название'),
  resourceId: Yup.string().required('Введите название'),
  name: Yup.string().matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке'),
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера')
});

export const validationTarifModal = Yup.object({
  countUsers: Yup.number().min(0, 'Значение не должно быть меньше 0').required('Введите значение')
});

export const validationSchemaPasswords = Yup.object({
  password: Yup.string().matches(
    passwordRegex,
    'Не менее 8 символов. Должен включать строчные и заглавные буквы, цифры и символы ( ) / . , - + #'
  ),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('password') as unknown as string], 'Пароли должны совпадать')
    .required('Подтвердите пароль')
});

export const validationProductAddSchema = Yup.object({
  purchaseprice: Yup.number().required('Введите стоимость закупки'),
  price: Yup.number()
    .required('Введите розничную цену')
    .when('purchaseprice', (purchaseprice: any, schema) => {
      if (purchaseprice) {
        return schema.min(purchaseprice, 'Розничная цена ниже закупочной');
      }

      return schema;
    }),
  name: Yup.string().required('Введите название продукта'),
  category: Yup.string().required('Выберите категорию')
});

export const validationServiceSchema = Yup.object({
  name: Yup.string().required('Введите название услуги'),
  tarif: Yup.number().required('Введите цену услуги')
});

export const validationFilialSchema = Yup.object({
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера'),
  name: Yup.string().required('Введите название бренда'),
  city: Yup.string().required('Введите название города'),
  street: Yup.string().required('Введите название улицы'),
  house: Yup.string().required('Введите номер дома'),
  flat: Yup.string().required('Введите номер офиса/помещения')
});

export const validationTelegramBotSchema = Yup.object({
  hello_text: Yup.string().required('Введите приветственное сообщение'),

  main_menu_text: Yup.string().required('Введите текст сообщения главного меню'),
  employee_message_text: Yup.string().required('Введите текст сообщения "Выберите мастера"'),
  service_message_text: Yup.string().required('Введите текст сообщения "Выберите услугу"'),

  service_button_text: Yup.string().required('Введите название кнопки "Услуги"'),
  employee_button_text: Yup.string().required('Введите название кнопки "Сотрудники"'),
  about_button_text: Yup.string().required('Введите название кнопки "Как нас найти"')
});

export const validationCreateChat = Yup.object({
  chatName: Yup.string().required('Введите название')
});

export const validationCreateSaleProductCategorySchema = Yup.object({
  category: Yup.string().required('Введите название')
});

export const validationSalesSchema = Yup.object({
  name: Yup.string().required('Введите название услуги'),
  tarif: Yup.number().required('Введите цену услуги')
});

export const validationSalesProductSchema = Yup.object({
  name: Yup.string().required('Введите название услуги'),
  tarif: Yup.number().required('Введите цену услуги')
});

export const validationSaleClientAddSchema = Yup.object({
  email: Yup.string().email('Введите корректный email'),
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера'),
  name: Yup.string().matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке')
});
export const validationPersonalSiteSchema = Yup.object({
  idonline: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'Поле должно содержать только английские буквы и цифры без пробелов')
    .required('Поле обязательно для заполнения') // Опционально: добавьте, если поле обязательно
});

export const validationClientModalAddSchema = Yup.object({
  email: Yup.string()
    .test('valid-characters', 'Email содержит недопустимые символы', (value) => !value || /^[A-Za-z0-9@._-]+$/.test(value))
    .email('Введите корректный email')
    .nullable(),
  phone: Yup.string().required('Введите телефон').length(16, 'Некорректный формат номера'),
  name: Yup.string()
    .required('Введите имя')
    .matches(/^[А-Яа-яЁё\s]+$/, 'Введите ФИО на русском языке')
});
