import styled from 'styled-components';

import themelight from '../../../../../app/providers/ThemeProvider/ui/theme';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${themelight.newColor.base01};
  .timetable-tabs {
    padding: 0 12px;
  }
  .employee-dropdown {
    &::after {
      border-radius: 5px;
      border: none;
    }
    .custom-select__value-container {
      padding: 0 0 0 1rem;
    }
    .custom-select__placeholder {
      display: block;
    }
    .custom-select__indicators {
      opacity: 1;
    }
    .custom-select__indicator {
      padding: 4px;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: ${themelight.newColor.black02};
    }
    .additionalSvg {
      position: absolute;
      top: 53%;
      left: 22px;
      transform: translateY(-50%);
    }
  }
  .filled div.custom-select__control.css-13cymwt-control {
    border: none !important;
  }
  .additionalSvg svg {
    left: 24px;
  }
  .custom-select__control {
  }

  .additionalHidden span {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px; /* 200% */
  }
`;

export const MobileAbsoluteDropdown = styled.div`
  //width: calc(100% - 2rem);
  margin: 0 auto;
  position: relative;
  height: 100%;
  max-height: 100%;

  //top: 50px;
  //z-index: 6;
  > div {
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: ${(props) => props.theme.text.text01};
      }
    }
  }
  &.padding {
    width: 100%;
  }
  &.padding-bottom {
    padding-bottom: 50px;
  }
`;
export const MobileSheetTable = styled(FlexWithAlign)`
  position: relative;
  gap: 0;
  > div {
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0;
    border-bottom: 1px solid ${(props) => props.theme.color.base04};
    padding-block: 0.5rem;
  }
`;
export const MobileCenter = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;
export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // overflow: hidden;
  padding: 24px 0 0 0;
  height: 100%;
  gap: 24px;
  .custom-select__control .custom-select__value-container {
    padding: 0 0 0 1rem;
  }
  @media (max-width: 767px) {
    padding: 0 1rem 80px 1rem;
  }
`;

export const ContentTop = styled.div`
  padding: 0 24px;
  display: grid;
  align-items: center;
  gap: 24px;
  grid-template-columns: 2fr 1.5fr 2fr;
  min-height: 50px;

  @media (max-width: 1500px) {
  }
`;

export const ContentTopRight = styled(FlexWithAlign)`
  gap: 16px;
  .view-dropdown {
  }
  .employee-dropdown {
    max-width: 100%;
    width: fit-content;
    .custom-select__indicator {
      color: ${themelight.newColor.black02};
    }
  }
`;
export const ContentTopLeft = styled(FlexWithAlign)`
  justify-content: flex-end;
  .change-btn {
    min-width: 167px;
  }
`;

export const EmployeeAddBtn = styled(CommonButton)``;
export const EmployeeWrapper = styled.div`
  padding-top: 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 7px;
  max-width: 329px;
  min-width: 329px;
  flex-shrink: 0;

  .employee-skeleton {
    span {
      height: 48px;
    }
  }
  @media (max-width: 1024px) {
    max-width: 250px;
    min-width: 250px;
  }
  @media (max-width: 767px) {
    max-width: 42px;
    min-width: 42px;
    padding-top: 38px;
    padding-right: 0;
    .employee-skeleton {
      gap: 4px;
      span {
        height: 64px;
      }
    }
  }
  @media print {
    & {
      max-width: 200px;
      min-width: 200px;
    }
  }
`;

export const EmployeeList = styled.ul`
  padding: 0 0 0 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  @media (max-width: 767px) {
    width: 42px;
    padding: 0;
    gap: 2px;
  }
  @media print {
    & {
      gap: 0;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
    }
  }
`;

export const EmployeeItem = styled.li`
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  min-height: 77px;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 2px;
    height: 60px;
    gap: 0;
    min-height: 60px;
    p {
      max-width: 64px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .name {
    font-size: 15px;
    line-height: 24px;
  }
  .name-wrap {
    padding: 10px 7px 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-right: 1px solid #ebebeb;
    height: 70%;
    @media (max-width: 767px) {
      max-width: 34px;
      > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 500;
      }
    }
  }
  @media print {
    & {
      grid-template-columns: 1fr;
      svg {
        display: none;
      }
      .name-wrap {
        border-right: none;
      }
    }
    .print-hidden {
      overflow: hidden;
      height: 0;
      width: 0;
    }
    &:not(:last-child) {
      border-bottom: 2px solid black;
      min-height: 76px;
    }
  }
`;

export const ScheduleWrap = styled.div`
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  z-index: 5;
  gap: 10px;
  .schedule-skeleton-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .schedule-skeleton {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    padding: 0 7.5px;
    span {
      height: 58px;
      width: 46px;
      border-radius: 8px;
      flex-shrink: 0;
    }
  }
  @media (max-width: 767px) {
    .schedule-skeleton-wrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .schedule-skeleton {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 4px;

      span {
        height: 58px;
        width: 46px;
        border-radius: 8px;
        flex-shrink: 0;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 15px;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid #fcfcfc;
    background-clip: padding-box;
    background: ${(props) => props.theme.color.gray8B};
  }

  &::-webkit-scrollbar-track {
    background: #fcfcfc;
  }
  &::-webkit-scrollbar-button:single-button {
    height: 2px;
    width: 5px;
    border-style: solid;
  }
  &::-webkit-scrollbar-button:single-button:decrement {
    border-width: 7px 5px 7px 0;
    border-color: transparent #8b8b8b transparent transparent;
  }

  &::-webkit-scrollbar-button:single-button:decrement:hover {
    border-color: transparent black transparent transparent;
  }
  &::-webkit-scrollbar-button:single-button:increment {
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent #8b8b8b;
  }
  &::-webkit-scrollbar-button:single-button:increment:hover {
    border-color: transparent transparent transparent black;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.gray8B};
  }
  &:hover::-webkit-scrollbar-track {
    background: #fcfcfc;
  }

  &:hover {
    --fix: ;
  }

  &.open-modal {
    width: calc(82% - 388px);
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .react-calendar {
    max-width: 100%;
    padding-top: 24px;
    @media (max-width: 767px) {
      padding-top: 0px;
    }
  }

  .react-calendar__viewContainer {
    padding: 0;
  }

  .react-calendar__navigation {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 0 24px 0;
    border-radius: 8px;
    gap: 16px;
    background-color: transparent;
    @media (max-width: 1500px) {
      top: 60px;
    }
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .react-calendar__navigation__label {
    flex-grow: 0;
  }

  //.react-calendar__navigation__arrow svg {
  //  width: 24px;
  //  height: 24px;
  //}
  .react-calendar__month-view__weekdays {
    display: none !important;
  }
  .react-calendar__month-view__days {
    gap: 0;
    flex-wrap: nowrap !important;
    & button:first-child {
      margin-inline: 0 !important;
    }
    & > button.react-calendar__month-view__days__day {
      padding: 0 8px 8px 8px;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 4px;
      width: 77px;
      height: 28px;
      max-width: 77px;
      min-width: 77px;
      max-height: 40px;
      background: transparent;
      border-radius: 4px;
      line-height: 18px;

      @media (max-width: 767px) {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
      }
      &:hover,
      &:focus {
        background: rgba(255, 127, 65, 0.5);
      }
      &.react-calendar__tile--now {
        background: rgba(255, 127, 65, 0.5);
        @media (max-width: 767px) {
          background: transparent;
        }
        abbr {
          border: none;
        }
      }
      abbr {
        display: none;
      }
      span {
        &.day {
          @media (max-width: 767px) {
            color: ${(props) => props.theme.text.text01};
            &.prev_day {
              color: #c5c5c5e5;
            }
          }
        }
        &.weekday {
          // color: rgba(27, 31, 59, 0.65);
          text-transform: initial;
          font-size: 11px;
          // letter-spacing: 0.4000000059604645px;
          text-transform: capitalize;
          @media (max-width: 767px) {
            color: ${(props) => props.theme.text.text01} !important;
            &.prev_day {
              color: #c5c5c5e5 !important;
            }
          }
        }
      }
    }
  }

  .react-calendar__navigation__label {
    flex-grow: 0 !important;
  }
  @media print {
    & {
      max-width: 70%;
    }
  }
`;

export const MobileEmployeePosition = styled(FlexWithAlign)`
  padding-block: 2rem;
  max-height: 30rem;
  overflow-y: auto;
  justify-content: flex-start;
  gap: 0;
  > button {
    border-top: 1px solid ${(props) => props.theme.color.base04};
    padding-block: 1rem;
    justify-content: flex-start !important;
    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.color.base04};
    }
  }
`;
export const TargetElement = styled.div<{ left: string }>`
  position: relative;
  top: 0;
  left: calc((${(props) => props.left} - 1) * 50px);
  width: 46px;
  height: 0;
  visibility: hidden;
  background-color: red;
`;

export const TargetElementDesktop = styled.div<{ left: string }>`
  position: relative;
  top: 0;
  left: calc((${(props) => props.left} - 1) * 75px);
  width: 75px;
  height: 0;
  visibility: hidden;
  background-color: red;
`;

export const EmployeeScheduleWrap = styled.div`
  display: flex;
  max-width: 100%;
  gap: 2px;
  height: 100%;
  @media print {
    & {
      gap: 0px;
    }
  }
`;

export const OperatingModeItem = styled.div`
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  min-height: 75px;
  color: #fff;
  background-color: #2a2c32;
  border-radius: 4px;
  .name-wrap {
    padding: 10px 7px 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-right: 1px solid #ebebeb;
    height: 70%;
    @media (max-width: 767px) {
      max-width: 34px;
      > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 767px) {
    min-height: 60px;
  }
  @media print {
    & {
      display: none;
    }
    .print-hidden {
      overflow: hidden;
      height: 0;
      width: 0;
    }
  }
`;
export const HoursInfo = styled(FlexWithAlign)`
  font-size: 10px;
  line-height: 18px;
  color: #8c8b88;
  .value {
    font-size: 12px;
    line-height: 16.8px;
  }
`;

export const TimeInfoWrap = styled.span`
  display: flex;
  flex-direction: column;
  @media print {
    .print-hidden {
      overflow: hidden;
      height: 0;
    }
    .print-show {
      display: flex;
    }
  }
`;

export const OperatingModeTimeWrap = styled.span`
  margin-top: 1px;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  color: ${(props) => props.theme.text.textGrey};
`;
