import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IService, ISubproductCabinets, ISubproductEmployee } from './service.interface';

const initialState: IService = {
  id: '',
  color: 'default',
  duration: '',
  fixtarif: false,
  group: '',
  images: '',
  intervalonlinebooking: 15,
  lessonnumber: 0,
  name: '',
  onlinebooking: false,
  programmservice: '',
  tarif: '',
  categoryId: '',
  seatsmax: 5,
  seatsmin: 1,
  comment: '',
  cabinets: [],
  employee: []
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setCategoryId: (state, action: PayloadAction<string>) => {
      state.categoryId = action.payload;
    },
    setColor: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    setDuration: (state, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
    setFixTarif: (state, action: PayloadAction<boolean>) => {
      state.fixtarif = action.payload;
    },
    setGroup: (state, action: PayloadAction<string>) => {
      state.group = action.payload;
    },
    setImages: (state, action: PayloadAction<string>) => {
      state.images = action.payload;
    },
    setIntervalOnlineBooking: (state, action: PayloadAction<number>) => {
      state.intervalonlinebooking = action.payload;
    },
    setLessonNumber: (state, action: PayloadAction<number>) => {
      state.lessonnumber = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setOnlineBooking: (state, action: PayloadAction<boolean>) => {
      state.onlinebooking = action.payload;
    },
    setProgrammService: (state, action: PayloadAction<string>) => {
      state.programmservice = action.payload;
    },
    setTarif: (state, action: PayloadAction<string>) => {
      state.tarif = action.payload;
    },
    setSeatsMax: (state, action: PayloadAction<number>) => {
      state.seatsmax = action.payload;
    },
    setSeatsMin: (state, action: PayloadAction<number>) => {
      state.seatsmin = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setCabinets: (state, action: PayloadAction<ISubproductCabinets[]>) => {
      state.cabinets = action.payload || [];
    },
    setEmployees: (state, action: PayloadAction<ISubproductEmployee[]>) => {
      state.employee = action.payload || [];
    },
    clearService: (state) => {
      state.id = '';
      state.color = 'default';
      state.duration = '';
      state.fixtarif = false;
      state.group = '';
      state.images = '';
      state.intervalonlinebooking = 0;
      state.lessonnumber = 0;
      state.name = '';
      state.onlinebooking = false;
      state.programmservice = '';
      state.tarif = '';
      state.categoryId = '';
      state.seatsmax = 5;
      state.seatsmin = 1;
      state.comment = '';
      state.cabinets = [];
      state.employee = [];
    }
  }
});

export const { reducer, actions } = serviceSlice;
export const {
  clearService,
  setColor,
  setDuration,
  setFixTarif,
  setGroup,
  setImages,
  setIntervalOnlineBooking,
  setLessonNumber,
  setName,
  setOnlineBooking,
  setProgrammService,
  setTarif,
  setCategoryId,
  setComment,
  setSeatsMax,
  setSeatsMin,
  setEmployees,
  setCabinets
} = actions;
