import React, { FC, memo, useState } from 'react';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import MessegeSenderAvatar from './avatars/MessegeSenderAvatar';
import {
  BubblesGroup,
  DateSeparator,
  Message,
  MessageBubble,
  NotTextChat,
  Scrollable,
  TelegramDefaultMessageContainer
} from './Messages.styled';
import TelegramBotOffline from './telegram/TelegramBotOffline';
import TelegramBotOnline from './telegram/TelegramBotOnline';

import { cn } from '../../../../../shared/lib';
import { useChatScroll } from '../../../../../shared/lib/hooks/useScrollTracker';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { useProfileUserQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { getCookie } from '../../../../../utils/cookies';
import { BubblesInner } from '../../../../../utils/styleUtils';
import { formatMessageDate } from '../../../lib/messenger.helper';

const Messages: FC = memo(() => {
  const { messages, status, websocketroomId } = useTypedSelector((state) => state.chat);
  const { user } = useTypedSelector((state) => state.user);
  const { data: activeFilialMessenger } = useFilialQuery();
  const { roomId } = useParams();
  const { userChats } = useTypedSelector((state) => state.chat);
  const findChat = userChats.find((item) => item.chat_id === roomId);
  const [lastAnimatedIndex, setLastAnimatedIndex] = useState(-1);
  const ref = useChatScroll(messages);
  const findSupportChat = userChats.find((item) => item.is_tech_support);
  const isSupportChat = roomId == 'support' ? findSupportChat : findChat;
  const messagesRecords = useTypedSelector((state) => state.chat);
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));

  console.log(messagesRecords)
  const calculateAnimationDelay = (index: number) => {
    return messages && Math.min((messages?.length - index - 1) * 0.05, 0.3);
  };

  const findNameTechChat = (msg: any) => {
    if (roomId !== 'support' && roomId !== 'telebot') {
      return user?.find((item) => item.id === msg.sender)?.fio;
    }
    if (roomId == 'support') {
      if (profileUser?.id == '6686be7821ca69fc50d76931' || profileUser?.id == '65a8d81df5353df12690729f') {
        if (msg?.sender == '6686be7821ca69fc50d76931' || msg.sender == '65a8d81df5353df12690729f') {
          return 'Служба поддержки';
        } else {
          const userName = userChats
            .find((cht) => cht.chat_id == websocketroomId)
            ?.chat_name?.split(' ')
            .slice(4, -1)
            .join(' ');

          return userName;
        }
      } else {
        return user?.find((item) => item.id === msg.sender)?.fio ? user?.find((item) => item.id === msg.sender)?.fio : 'Служба поддержки';
      }
    }
  }

  const groupAndSortMessagesByDay = (msg: any) => {
    const sortedMessages = msg && msg.slice().sort((a: any, b: any) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix());

    const groupedAndSortedMessages: { [key: string]: JSX.Element[] } = {};

    sortedMessages?.forEach((msg: any, index: any) => {
      const day = dayjs(msg.created_at).format('YYYY-MM-DD');

      if (!groupedAndSortedMessages[day]) {
        groupedAndSortedMessages[day] = [];
      }

      const shouldShowDateSeparator = index === 0 || day !== dayjs(sortedMessages[index - 1].created_at).format('YYYY-MM-DD');

      if (shouldShowDateSeparator) {
        groupedAndSortedMessages[day].push(
          <DateSeparator key={`date-separator-${msg.id}`}>
            <div className={'bubble-content'}>
              <div className='service-msg'>{dayjs(day).format('DD MMMM YYYY')}</div>
            </div>
          </DateSeparator>
        );
      }

      groupedAndSortedMessages[day].push(
        <Message
          onAnimationEnd={() => {
            const shouldAnimate = groupedAndSortedMessages[day].length - 1 > lastAnimatedIndex;
            if (shouldAnimate) {
              setLastAnimatedIndex(lastAnimatedIndex + 1);
            }
          }}
          style={{ animationDuration: `${calculateAnimationDelay(groupedAndSortedMessages[day].length - 1)}s` }}
          className={cn(getCookie('id') === msg.sender ? 'is-out' : 'is-in')}
          key={`message-${msg.id}`}
        >
          <div className={'bubble-content-wrapper'}>
            <div className='avatar'>
              <MessegeSenderAvatar
                message={msg}
                isSupportChat={isSupportChat}
              />
            </div>
            <div className={'buttle-content'}>
              <div className={'time-and-name-container'}>
                <div className='user-name'>
                  <div className={'colored-name name floating-part'}>
                    <div className={'title-flex'}>
                      <span className={'peer-title'}>
                        {roomId === 'telebot' && 'Telegram Bot'}
                        {findNameTechChat(msg)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={'time-inner'}>
                  <span className={'i18n'}>{formatMessageDate(msg.created_at)}</span>
                </div>
              </div>
              <div className='message'>
                <div dangerouslySetInnerHTML={{ __html: msg.content }} />
              </div>
            </div>
          </div>
        </Message>
      );
    });

    return groupedAndSortedMessages;
  };

  return (
    <MessageBubble>
      <Scrollable
        ref={ref}
        className={'telegram'}
      >
        {roomId === 'telebot' ? (
          messagesRecords.messages && messagesRecords?.messages?.length < 0 ? (
            <TelegramDefaultMessageContainer>
              {activeFilialMessenger?.tgbot ? <TelegramBotOnline></TelegramBotOnline> : <TelegramBotOffline></TelegramBotOffline>}
            </TelegramDefaultMessageContainer>
          ) : (
            <BubblesInner className={'bubbles-inner has-rights'}>
              {Object.values(groupAndSortMessagesByDay(messagesRecords.messages)).map((groupedMessages) => (
                <BubblesGroup key={`bubbles-group-${groupedMessages[0].key}`}>{groupedMessages}</BubblesGroup>
              ))}
              {status !== 'pending' && messages && messages.length === 0 && <NotTextChat>В данном чате еще не было сообщений</NotTextChat>}
            </BubblesInner>
          )
        ) : (
          <BubblesInner className={'bubbles-inner has-rights'}>
            {Object.values(groupAndSortMessagesByDay(messages)).map((groupedMessages) => (
              <BubblesGroup key={`bubbles-group-${groupedMessages[0].key}`}>{groupedMessages}</BubblesGroup>
            ))}
            {status !== 'pending' && messages && messages.length === 0 && <NotTextChat>В данном чате еще не было сообщений</NotTextChat>}
          </BubblesInner>
        )}
      </Scrollable>
    </MessageBubble>
  );
});

Messages.displayName = 'Messages';
export default Messages;
